import React from 'react';
import Modal, { ModalBody, ModalHeader } from '../bootstrap/Modal';
import Card, { CardBody } from '../bootstrap/Card';
import Icon from '../icon/Icon';
import Button from '../bootstrap/Button';
import ERRORS from '../../common/data/errorData';
import Accordion, { AccordionItem } from '../bootstrap/Accordion';

export default function ErrorPopup({ error, setOpen }) {
    return (
        <Modal isOpen={true} setIsOpen={() => { }} isCentered size={'sm'} classNameMd='nv-3'>
            <ModalHeader
                setIsOpen={() => {
                    setOpen(false);
                }}></ModalHeader>
            <ModalBody className='row g-4 text-center px-4 pb-0'>
                <Icon icon={'Cancel'} size='7x' color={'danger'} className={'mt-0'} />
                <h2>{!!error?.response?.status && 'Erreur ' + error?.response?.status}</h2>
                <p>{!!error?.response?.status ? ERRORS[error?.response?.status] : error}</p>


                <Button
                    color={'danger'}
                    isLight
                    className='w-100 py-3 text-uppercase'
                    size='lg'
                    onClick={() => {
                        setOpen(false);
                    }}>
                    Ok
                </Button>
                {!!error?.response?.data && (
                    <Accordion id='accSample' activeItemId={false} className='px-0 mt-1' shadow={"none"} color='dark' >
                        <AccordionItem
                            headerTag="span"
                            tag={"div"}
                            id='accor1'
                            title={<small className='text-muted fw-normal'><Icon icon='InfoOutline' size={'lg'} className='me-2'></Icon>En savoir plus</small>}
                        // icon='InfoOutline'
                        >
                            <div className={"break-word fs-6"} >{JSON.stringify(error?.response?.data)}</div>

                        </AccordionItem>
                    </Accordion>
                )}
            </ModalBody>
        </Modal>
    );
}
