import { useState, useContext, useCallback, useEffect } from 'react';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../bootstrap/Modal';
import Button from '../bootstrap/Button';
import FormGroup from '../bootstrap/forms/FormGroup';
import Input from '../bootstrap/forms/Input';
import Spinner from '../bootstrap/Spinner';
import { useFormik } from 'formik';
import { createProject } from '../../odooApi/activites';
import AuthContext from '../../contexts/authContext';
import ErrorPopup from '../extras/ErreurPopup';
import ProjectsContext from '../../contexts/projectsContext';
import { useNavigate } from 'react-router-dom';
import { ProjectsMenu } from '../../gestioneerMenu';
import { generateProjectKey } from '../../helpers/helpers';

export default function AddProject({ setisOpen }) {
    const [isLoading, setisLoading] = useState(false);
    const { userData } = useContext(AuthContext);
    const { projects, setProjects } = useContext(ProjectsContext);
    const navigate = useNavigate();
    const handleOnClickToProjectPage = useCallback(
        (id) => navigate(`../${ProjectsMenu.projectID.path}/${id}`),
        [navigate],
    );

    const [error, setError] = useState();
    const formik = useFormik({
        initialValues: {
            name: '',
            x_key: ''
        },
        onSubmit: (values) => {
            setisLoading(true);
            createProject(
                userData?.password,
                values,
                (result) => {
                    setProjects((prev) => [...prev, result]);
                    handleOnClickToProjectPage(result?.id)
                    setisOpen(false);
                },
                (error) => {
                    setisLoading(false);
                    setError(error);
                },
            );
        },
    });


    useEffect(() => {
        formik?.setFieldValue("x_key", generateProjectKey(formik?.values?.name))
    }, [formik?.values?.name])

    return (
        <Modal
            setIsOpen={() => { }}
            isOpen={true}
            size='sm'
            isCentered
            classNameMd='nv-3'>
            <form onSubmit={formik.handleSubmit}>
                <ModalHeader className='px-4 pt-3 pb-0' setIsOpen={() => setisOpen(false)}>
                    <div className='w-100 text-muted text-center'>
                        <ModalTitle id='project-edit'>Créer un projet</ModalTitle>
                    </div>
                </ModalHeader>
                <ModalBody className='px-4'>
                    <FormGroup id='name' label='Titre' isFloating>
                        <Input onChange={formik.handleChange} value={formik.values.name} />
                    </FormGroup>
                    <FormGroup id='x_key' label='Key' isFloating className='mt-3'>
                        <Input onChange={formik.handleChange} value={formik.values.x_key} />
                    </FormGroup>

                </ModalBody>
                <ModalFooter className='px-4 pb-4'>
                    <Button color='primary' className='w-100' type='submit' isDisable={isLoading} isLoading>
                        {isLoading && <Spinner isSmall inButton />}
                        {isLoading ? 'En cours' : 'Sauvegarder'}
                    </Button>
                </ModalFooter>
            </form>
            {!!error && (
                <ErrorPopup
                    error={error}
                    setOpen={setError}
                />
            )}
        </Modal>
    );
}
