import React, { createContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';


const ProjectsContext = createContext([]);


export const ProjectsContextProvider = ({ children }) => {
    const [projects, setProjects] = useState([]);

    const value = useMemo(
        () => ({
            projects,
            setProjects
        }),
        [projects],
    );
    return <ProjectsContext.Provider value={value}>{children}</ProjectsContext.Provider>;
};
ProjectsContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default ProjectsContext;