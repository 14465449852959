export const DashboardMenu = {
	id: 'dashboard',
	text: 'Tableau de bord',
	path: '/',
	icon: 'Dashboard',
	permissions: ['admin', 'developpeur','designeur',"marketing", 'seo','redaction','comptabilite','gestionnaire'],
};

export const EmployeeTable = {
	id: 'EmployeeTable',
	text: 'Employés',
	icon: 'SupervisorAccount',
	path: 'RH',
	permissions: ['superadmin', 'admin'],
	employee: {
		id: 'employee',
		text: 'Employee',
		path: 'RH/employee',
		hide: true,
	},
};


export const ProfilMenu = {
	id: 'profil',
	text: 'Profil',
	icon: 'SupervisorAccount',
	path: 'profil',
	permissions: ['superadmin', 'admin'],
	
};
export const ActivityMenu = {
	id: 'activity',
	text: 'Mes Tâches',
	icon: 'Book',
	path: 'taches',
	permissions: ['admin', 'developpeur','designeur',"marketing", 'seo','redaction','comptabilite','gestionnaire'],
};

export const AllProjectMenu = {
	id: 'allProjects',
	text: 'Liste de projets',
	icon: 'Book',
	path: 'projects',
	permissions: ['admin', 'developpeur','designeur',"marketing", 'seo','redaction','comptabilite','gestionnaire'],
};

export const UserRecordings= {
	id: 'allProjects',
	text: 'Enregistrements utilisateur',
	icon: 'Book',
	path: 'recordings',
	permissions: ['admin', 'developpeur','designeur',"marketing", 'seo','redaction','comptabilite','gestionnaire'],
};

export const ProjectsMenu = {
	id: 'projects',
	text: 'Projets',
	icon: 'AutoStories',
	path: 'projets',
	permissions: ['admin', 'developpeur','designeur',"marketing", 'seo','redaction','comptabilite','gestionnaire'],
	projectID : {
		id: 'project',
		text: 'Projet',
		icon: 'AutoStories',
		path: 'projets',
		hide:true
	},
	// subMenu: {
	// 	Store2000: {
	// 		id: 'Store2000',
	// 		text: 'Store 2000',
	// 		path: 'projets/Store2000',
	// 		icon: 'ViewArray',
	// 		projectId : 13 
	// 	},
	// 	RMP: {
	// 		id: 'RMP',
	// 		text: 'Rideau Métallique Paris',
	// 		path: 'projets/RMP',
	// 		icon: 'ViewArray',
	// 		projectId : 10
	// 	}
	// }
};

export const SectorsMenu = {
	id: 'secteur',
	text: 'Secteur',
	icon: 'AutoStories',
	path: 'secteur',
	hide:true,
	permissions: ['admin', 'developpeur','designeur',"marketing", 'seo','redaction','comptabilite','gestionnaire'],
};

/*****  Les modules  ******/

export const ActivityModule = {
	Activités: ActivityMenu,
	AllProjectMenu : AllProjectMenu,
	Projets: ProjectsMenu,
	UserRecordings:UserRecordings,
	permissions: ['admin', 'developpeur','designeur',"marketing", 'seo','redaction','comptabilite','gestionnaire'],
};

/********* Final modules **********/

export const modules = {
	Projets: ActivityModule,
};