import {
	useContext,
	useLayoutEffect,
	useState,
} from 'react';
import PropTypes from 'prop-types';
import { useTour } from '@reactour/tour';
import { HeaderRight } from '../../../layout/Header/Header';
import OffCanvas, {
	OffCanvasBody,
	OffCanvasHeader,
	OffCanvasTitle,
} from '../../../components/bootstrap/OffCanvas';
import AuthContext from '../../../contexts/authContext';
import Avatar from '../../../components/Avatar';
import Button from '../../../components/bootstrap/Button';
import AddProject from '../../../components/Activity/AddProject';
import { useNavigate } from 'react-router-dom';
import { ProfilMenu } from '../../../gestioneerMenu';

const CommonHeaderRight = ({ beforeChildren, afterChildren }) => {
	const { userData } = useContext(AuthContext);
	const [offcanvasStatus, setOffcanvasStatus] = useState(false)
	const [createProjectPopup, setCreateProjectPopup] = useState(false)
	const navigate = useNavigate();


	/**
	 * Language attribute
	 */
	// useLayoutEffect(() => {
	// 	document.documentElement.setAttribute('lang', i18n.language);
	// });
	const { setIsOpen } = useTour();
	return (
		<HeaderRight>
			<div className='row g-3'>
				{beforeChildren}
				{/* Tour Modal */}
				{userData?.role?.toLowerCase() == "admin" && <Button
					isLight
					icon={'AddCircleOutline'}
					color='storybook' className='w-auto py-0 me-2'
					onClick={() => { setCreateProjectPopup(true) }}>Créer un projet</Button>}

				<div className='col d-flex align-items-center cursor-pointer' onClick={() =>
					navigate(`../${ProfilMenu?.path}`)
				}>
					<div className='me-3'>
						<div className='text-end'>
							<div className='fw-bold fs-6 mb-0'>
								{`${userData?.name} ${userData?.surname}`}
							</div>
							<div className='text-muted'>
								<small>{userData?.position}</small>
							</div>
						</div>
					</div>
					<div className='position-relative'>
						<Avatar
							srcSet={userData?.srcSet}
							src={userData?.src}
							size={48}
							color={userData?.color}
							style={{ objectFit: 'cover', objectPosition: 'top' }}
						/>
						<span className='position-absolute top-85 start-85 translate-middle badge border border-2 border-light rounded-circle bg-success p-2'>
							<span className='visually-hidden'>Online user</span>
						</span>
					</div>
				</div>
				{afterChildren}
			</div>

			<OffCanvas
				id='notificationCanvas'
				titleId='offcanvasExampleLabel'
				placement='end'
				isOpen={offcanvasStatus}
				setOpen={setOffcanvasStatus}>
				<OffCanvasHeader setOpen={setOffcanvasStatus}>
					<OffCanvasTitle id='offcanvasExampleLabel'>Notifications</OffCanvasTitle>
				</OffCanvasHeader>
				<OffCanvasBody>
					{/* <Alert icon='ViewInAr' isLight color='info' className='flex-nowrap'>
						4 new components added.
					</Alert>
					<Alert icon='ThumbUp' isLight color='warning' className='flex-nowrap'>
						New products added to stock.
					</Alert>
					<Alert icon='Inventory2' isLight color='danger' className='flex-nowrap'>
						There are products that need to be packaged.
					</Alert>
					<Alert icon='BakeryDining' isLight color='success' className='flex-nowrap'>
						Your food order is waiting for you at the consultation.
					</Alert>
					<Alert icon='Escalator' isLight color='primary' className='flex-nowrap'>
						Escalator will turn off at 6:00 pm.
					</Alert> */}
				</OffCanvasBody>
			</OffCanvas>

			{!!createProjectPopup && <AddProject setisOpen={setCreateProjectPopup} />}
		</HeaderRight>
	);
};
CommonHeaderRight.propTypes = {
	beforeChildren: PropTypes.node,
	afterChildren: PropTypes.node,
};
CommonHeaderRight.defaultProps = {
	beforeChildren: null,
	afterChildren: null,
};

export default CommonHeaderRight;
