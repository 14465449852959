import React, { useContext, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import Brand from '../Brand/Brand';
import Navigation, { NavigationLine, NavigationTitle } from '../Navigation/Navigation';
import User from '../User/User';

import ThemeContext from '../../contexts/themeContext';

import Tooltips from '../../components/bootstrap/Tooltips';
import useAsideTouch from '../../hooks/useAsideTouch';
import AuthContext from '../../contexts/authContext';
import { modules } from '../../gestioneerMenu';
import { useMediaQuery } from 'react-responsive'
import ProjectsContext from '../../contexts/projectsContext';
import { getAllProjects } from '../../odooApi/activites';

const Aside = () => {
	const { asideStatus, setAsideStatus } = useContext(ThemeContext);

	const { asideStyle, touchStatus, hasTouchButton, asideWidthWithSpace, x } = useAsideTouch();

	const isModernDesign = process.env.REACT_APP_MODERN_DESGIN === 'true';
	const isMobile = useMediaQuery({ query: '(max-width: 991.98px)' })
	const isDesktop = !isMobile

	const constraintsRef = useRef(null);

	const { userData } = useContext(AuthContext);
	const { projects, setProjects } = useContext(ProjectsContext);

	const onToggle = React.useCallback(() => {
		setAsideStatus(prev => !prev)
	}, [setAsideStatus])

	const getProjectsSubMenu = (projects) => {
		const subMenu = {};
		projects?.forEach((project) => {
			subMenu[project.id] = {
				id: project.id,
				text: project.name,
				path: `projets/${project.id}`,
				icon: project.icon,
				// projectId: project.projectId,
			};
		});

		return subMenu;
	};

	useEffect(() => {
		getAllProjects(userData?.password, (result) => {
			setProjects(result?.elements?.filter(i => i?.subtask_project_id == i?.id))
		})
	}, []);

	useEffect(() => {
		if (projects?.length > 0) { modules["Projets"]["Projets"]["subMenu"] = getProjectsSubMenu(projects) }
	}, [projects])
	return (
		<>
			<motion.aside
				style={asideStyle}
				className={classNames(
					'aside',
					{ open: isDesktop || (isMobile && asideStatus) },
					{
						'aside-touch-bar': hasTouchButton && isModernDesign,
						'aside-touch-bar-close': !touchStatus && hasTouchButton && isModernDesign,
						'aside-touch-bar-open': touchStatus && hasTouchButton && isModernDesign,
					},
				)}>
				<div className='aside-head'>
					<Brand hideToggle={isDesktop} onToggle={onToggle} />
				</div>

				<div className='aside-body'>
					{Object.entries(modules).map(([moduleId, moduleItems]) => {
						const hasModulePermission = !!moduleItems.permissions?.includes(
							userData?.role?.toLocaleLowerCase(),
						);
						if (hasModulePermission) {
							return (
								<>
									<NavigationLine />
									<ul className='navigation'>
										<NavigationTitle>
											{moduleId.replace(/_/g, ' ')}
										</NavigationTitle>
										{Object.entries(moduleItems).map(
											([submoduleId, submodule]) => {
												const hasSubmodulePermission =
													!!submodule?.permissions?.includes(
														userData?.role?.toLocaleLowerCase(),
													);

												if (hasSubmodulePermission) {
													return (
														<Navigation
															key={submodule.id}
															menu={[submoduleId, submodule]}
															id='aside-demo-pages'
														/>
													);
												}

											},
										)}
									</ul>
								</>
							);
						}
						return null;
					})}

				</div>
				<div className='aside-foot'>
					<User />
				</div>
			</motion.aside>
			{asideStatus && hasTouchButton && isModernDesign && (
				<>
					<motion.div className='aside-drag-area' ref={constraintsRef} />
					<Tooltips title='Toggle Aside' flip={['top', 'right']}>
						<motion.div
							className='aside-touch'
							drag='x'
							whileDrag={{ scale: 1.2 }}
							whileHover={{ scale: 1.1 }}
							dragConstraints={constraintsRef}
							// onDrag={(event, info) => console.log(info.point.x, info.point.y)}
							dragElastic={0.1}
							style={{ x, zIndex: 1039 }}
							onClick={() => x.set(x.get() === 0 ? asideWidthWithSpace : 0)}
						/>
					</Tooltips>
				</>
			)}
		</>
	);
};

export default Aside;
