import React, { createContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';


const ProjectContext = createContext([]);


export const ProjectContextProvider = ({ children }) => {
    const [project, setProject] = useState({});

    const value = useMemo(
        () => ({
            project,
            setProject
        }),
        [project],
    );
    return <ProjectContext.Provider value={value}>{children}</ProjectContext.Provider>;
};
ProjectContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default ProjectContext;