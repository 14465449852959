import React, { lazy } from 'react';
import {demoPages, layoutMenu } from '../menu';
import {
	ActivityMenu,
	ProjectsMenu,
	DashboardMenu,
	SectorsMenu,
	AllProjectMenu,
	EmployeeTable,
	ProfilMenu,
	UserRecordings
} from '../gestioneerMenu';
import Login from '../pages/presentation/auth/Login';


const AUTH = {
	PAGE_404: lazy(() => import('../pages/presentation/auth/Page404')),
};
const APP = {
	DASHBOARD: lazy(() => import('../pages/presentation/dashboard/CommonDashboard')),
	PROJECTS: lazy(() => import('../pages/presentation/Projects/index')),
	RECORDINGS: lazy(() => import('../pages/presentation/Recordings')),
	RECORD_PROJECT:lazy(()=>import('../pages/presentation/Recordings/Project')),
	RECORD_USER:lazy(()=>import('../pages/presentation/Recordings/UserRecord')),
	MY_TASKS: lazy(() => import('../pages/presentation/my-tasks/MyTasks')),
	SECTOR: lazy(() => import('../pages/presentation/Projects/ProjectDetails')),
	PROJECT: lazy(() => import('../pages/presentation/Projects/ProjectSectors')),
	PROFILE: lazy(() => import('../pages/presentation/profil/Profile')),
	CHAT: {
		WITH_LIST: lazy(() => import('../pages/presentation/chat/WithListChatPage')),
		ONLY_LIST: lazy(() => import('../pages/presentation/chat/OnlyListChatPage')),
	},
};
const PAGE_LAYOUTS = {
	HEADER_SUBHEADER: lazy(() => import('../pages/presentation/page-layouts/HeaderAndSubheader')),
	HEADER: lazy(() => import('../pages/presentation/page-layouts/OnlyHeader')),
	SUBHEADER: lazy(() => import('../pages/presentation/page-layouts/OnlySubheader')),
	CONTENT: lazy(() => import('../pages/presentation/page-layouts/OnlyContent')),
	BLANK: lazy(() => import('../pages/presentation/page-layouts/Blank')),
	ASIDE: lazy(() => import('../pages/presentation/aside-types/DefaultAsidePage')),
	MINIMIZE_ASIDE: lazy(() => import('../pages/presentation/aside-types/MinimizeAsidePage')),
};



const presentation = [
	/**
	 * Auth Page
	 */
	{
		path: demoPages.page404.path,
		element: <AUTH.PAGE_404 />,
		exact: true,
	},
	{
		path: demoPages.login.path,
		element: <Login />,
		exact: true,
	},
	{
		path: demoPages.signUp.path,
		element: <Login isSignUp />,
		exact: true,
	},
	{
		path: DashboardMenu.path,
		element: <APP.DASHBOARD />,
		exact: true,
	},
	/** Activités */
	
	{
		path: `${AllProjectMenu.path}`,
		element: <APP.PROJECTS />,
		exact: true,
	},
	{
		path: `${UserRecordings.path}`,
		element: <APP.RECORDINGS />,
		exact: true,
	},
	{
		path: `${UserRecordings.path}/:id`,
		element: <APP.RECORD_PROJECT />,
		exact: true,
	},
	{
		path: `${UserRecordings.path}/:idProject/:id`,
		element: <APP.RECORD_USER />,
		exact: true,
	},
	
	{
		path: `${ActivityMenu.path}`,
		element: <APP.MY_TASKS />,
		exact: true,
	},

	{
		path: `${ProjectsMenu.projectID.path}/:id`,
		element: <APP.PROJECT />,
		exact: true,
	},
	
	{
		path: `${ProjectsMenu.projectID.path}/:id/${SectorsMenu.path}/:id`,
		element: <APP.SECTOR />,
		exact: true,
	},

	{
		path: `${ProfilMenu?.path}`,
		element: <APP.PROFILE />,
		exact: true,
	},

	/** ************************************************** */

	/**
	 * Page Layout Types
	 */
	{
		path: layoutMenu.blank.path,
		element: <PAGE_LAYOUTS.BLANK />,
		exact: true,
	},

	{
		path: layoutMenu.pageLayout.subMenu.headerAndSubheader.path,
		element: <PAGE_LAYOUTS.HEADER_SUBHEADER />,
		exact: true,
	},
	{
		path: layoutMenu.pageLayout.subMenu.onlyHeader.path,
		element: <PAGE_LAYOUTS.HEADER />,
		exact: true,
	},
	{
		path: layoutMenu.pageLayout.subMenu.onlySubheader.path,
		element: <PAGE_LAYOUTS.SUBHEADER />,
		exact: true,
	},
	{
		path: layoutMenu.pageLayout.subMenu.onlyContent.path,
		element: <PAGE_LAYOUTS.CONTENT />,
		exact: true,
	},
	{
		path: layoutMenu.asideTypes.subMenu.defaultAside.path,
		element: <PAGE_LAYOUTS.ASIDE />,
		exact: true,
	},
	{
		path: layoutMenu.asideTypes.subMenu.minimizeAside.path,
		element: <PAGE_LAYOUTS.MINIMIZE_ASIDE />,
		exact: true,
	},
];

const contents = presentation;

export default contents;
