import React, { FC } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Icon from '../../components/icon/Icon';
import Logo from '../../components/Logo';
// import logo from '../../assets/logos/logo.svg';
import logo from '../../assets/logos/logo-DM.png'

interface IBrandProps {
	hideToggle: boolean;
	onToggle?: () => void;
}
const Brand: FC<IBrandProps> = ({ hideToggle = false, onToggle }) => {
	return (
		<div className='brand pt-4'>
			<div className='brand-logo'>
				<h1 className='brand-title '>
					<Link to='/' aria-label='Logo'>
						<img src={logo} alt='logo' className='h-100 w-100' />
						{/* <Logo height={32} /> */}
					</Link>
				</h1>
			</div>
			{!hideToggle && (
				<button
					type='button'
					className='btn brand-aside-toggle'
					aria-label='Toggle Aside'
					onClick={onToggle}>
					<Icon icon='FirstPage' className='brand-aside-toggle-close' />
					<Icon icon='LastPage' className='brand-aside-toggle-open' />
				</button>
			)}
		</div>
	);
};
Brand.propTypes = {
	hideToggle: PropTypes.bool.isRequired,
	onToggle: PropTypes.func.isRequired,
};

export default Brand;
